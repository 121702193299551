:root { 
--clr-item-name : #FFFFFF; 
--img-box-glow : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/6ba25664-affe-4206-9dea-ffddfecd5263.gif'); 
--img-btn-info : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/e131bbac-05a4-433a-92cb-5c8f3e3ffe30.png'); 
--img-btn-long : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/d9836ffd-aa36-41a6-8fb0-f22a66fea868.png'); 
--img-btn-shard-history : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/a34dc252-fc62-4c24-a3d1-408fe3216d66.png'); 
--img-exchange-emblem : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/a7e94520-00b6-49e9-a811-fc07abc6a952.png'); 
--img-ico-warning : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/3da0297d-a177-47e3-9c71-2a03b4dc893c.png'); 
--img-item-bg : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/46c6feba-3a6e-4229-9fec-9c2fd837804e.png'); 
--img-kv : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/9bb5e077-879b-4a67-be7b-e56be0dff187.png'); 
--img-main-bg : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/ddbbd265-7df8-4153-a7a5-7717e6f46338.jpg'); 
--img-popup-animated : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/c84a3a2f-5df0-458e-adc8-0f1d7b77c90a.png'); 
--img-popup-base-bg : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/5b7fd71d-8639-4e53-9cd2-f2db445afde5.png'); 
--img-popup-bg-overlay : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/406b8972-2b35-4a01-9c7a-bae28f3138be.png'); 
--img-popup-btn-grey : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/dc243999-95bb-4c8d-96e2-8a2addee59e2.png'); 
--img-popup-btn-yellow : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/c4717f8e-2fb2-41c7-bf4d-b338348fc515.png'); 
--img-popup-close : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/c9263c70-fa22-414a-9193-cf684fbfac00.png'); 
--img-popup-confirm-arrow : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/b7050528-e19d-4d37-8c24-14908f2387a9.png'); 
--img-popup-confirm-checkbox : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/e45842e7-51c2-457f-9613-31d5f57f4704.png'); 
--img-popup-confirm-minus : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/4db89ee5-2201-4b14-b81c-3f37e93fdcce.png'); 
--img-popup-confirm-plus : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/ba8dd712-029e-4526-88f1-8ea97a6652bb.png'); 
--img-popup-confirm-tick : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/745d7cd4-d95b-43ad-b6ad-cae2a21f42a0.png'); 
--img-popup-history-tab-active : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/77df990f-e531-4419-b003-218a27724c00.png'); 
--img-popup-history-tab-inactive : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/af62cd56-67fc-4237-a6b3-4ec3e66147f0.png'); 
--img-popup-m : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/05a6c3e7-a20c-42d2-b792-5813e35d879e.png'); 
--img-reward-box-bg : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/b5a12a5a-7125-44f3-954c-2bd994dff785.png'); 
--img-scrollbar : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/6d6e1015-b551-4845-af04-0b079c8c07ef.png'); 
--img-shop-bg : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/71f45351-d2a6-4f56-877c-61821b8599ac.png'); 
--img-title : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/399239b4-519e-4278-99da-2323806397a3.png'); 
--img-token-cp : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/69013515-7d8a-4f31-9f69-7a49eaae6dca.png'); 
--img-token-emblem : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/9315b9dd-f160-4ba4-b797-399a88b3f8f9.png'); 
--img-token-shard : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/33af6ff4-396c-42a9-80cf-7db88c06bae6.png'); 
}