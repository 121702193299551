.header {
	position: absolute;
	width: calc(100% - 1.25rem);
	height: 1.25rem;
	@include flexrc;
	justify-content: space-between;
	align-items: flex-start;
	padding: 0.12rem 0.6rem 0 0;

	.buttons-wrapper {
		width: 1.1rem;
		height: 0.42rem;
		@include flexrc;
		justify-content: space-between;

		.btn-info {
			@include bgv("btn-info");
			width: 0.42rem;
			height: 100%;
		}

		.btn-history {
			@include bgv("btn-shard-history");
			width: 0.42rem;
			height: 100%;
		}
	}

	.event-ico {
		@include bgv("logo");
		width: 2.1rem;
		height: 1.3rem;
		position: absolute;
		top: 0.12rem;
		bottom: 0;
		left: -1.25rem;
		right: 0;
		margin: auto;
		align-self: center;
	}

	.userinfo-wrapper {
		height: 0.5rem;
		display: flex;

		.nickname {
			align-self: center;
			color: #f2e02f;
			font-weight: bold;
			font-size: 0.24rem;
			margin-right: 0.2rem;
			@include ellipsis-line(1);
			text-align: right;
		}

		.cp,
		.emblem,
		.fragment {
			color: #ffffff;
			font-size: 0.26rem;
			font-weight: bold;
			display: flex;
			align-items: center;
			margin-right: 0.2rem;
			position: relative;

			span {
				width: 5ch;
				position: relative;
				text-align: right;
				display: block;
				&[class*="up"] {
					&.close {
						&::after {
							transform: translateY(150%);
							opacity: 1;
						}
					}
					&.open {
						&::after {
							transform: translateY(0%);
							opacity: 0;
						}
					}
				}
				&::after {
					position: absolute;
					right: 0;
					top: 0;
					content: attr(data-count);
					transform: translateY(150%);
					visibility: visible;
					opacity: 1;
					font-size: 0.24rem;
					font-family: $rfg-font-family;
					font-weight: 600;
					color: #fff;
					text-shadow: 0 0 0.1rem black, 0 0 0.1rem black;
					transition: 0.5s transform linear, 0.5s opacity linear;
				}
			}

			&-ico {
				margin-left: 0.08rem;
			}
		}

		.cp-ico {
			width: 0.3rem;
			height: 0.3rem;
			@include bgv("token-cp");
		}
		.fragment-ico {
			width: 0.33rem * 1.1;
			height: 0.23rem * 1.1;
			@include bgv("token-shard");
		}
		.emblem-ico {
			// width: 0.34rem;
			// height: 0.214rem;
			width: 0.471rem;
			height: 0.3rem;
			@include bgv("token-emblem");
		}
	}
}
