.modal-confirm-shards {
	.modal__container {
		@include flexcc;
		.modal__content {
			@include flexcc;
			.title {
				margin-top: 0.2rem;
			}
			.text {
				margin: 0.2rem auto 0;
				text-align: center;
				white-space: pre-line;
				font-size: 0.2rem;
				line-height: 0.25rem;
				color: #fff;
				font-weight: 600;
				y {
					color: #ffe940;
					text-transform: capitalize;
				}
				.cp-ico {
					display: inline-block;
					margin: 0 0.5ch;
					width: 0.22rem;
					height: 0.22rem;
					@include bgv("token-cp");
					vertical-align: middle;
				}
				.emblem-ico {
					display: inline-block;
					// margin: 0 0.5ch;
					width: 0.471rem;
					height: 0.3rem;
					@include bgv("token-emblem");
					vertical-align: middle;
				}
				.shard-ico {
					display: inline-block;
					margin: 0 0.5ch;
					width: 0.33rem * 1.1;
					height: 0.23rem * 1.1;
					@include bgv("token-shard");
					vertical-align: middle;
				}
			}
			.exchange {
				width: 85%;
				font-size: 0.4rem;
				line-height: 0.44rem;
				color: #fff;
				text-align: center;
				margin-top: auto;
				.emblem-ico {
					display: inline-block;
					margin: 0 0.5ch;
					width: 0.36rem * 1.5;
					height: 0.36rem * 1.5;
					@include bgv("token-emblem");
					background-size: 120% !important;
				}
				.arrow {
					display: inline-block;
					@include bgv("popup-confirm-arrow");
					width: 0.6rem;
					height: 0.3rem;
				}
				.shards-ico {
					display: inline-block;
					margin: 0 0.5ch;
					width: 0.3rem * 1.4;
					height: 0.22rem * 1.4;
					@include bgv("token-shard");
				}
				> span {
					display: inline-block;
					width: 5ch;
				}
				.group {
					display: inline-flex;
					align-items: center;
					justify-content: center;
					width: initial;
					height: initial;
					> span {
						display: inline-block;
						width: 3ch;
					}
					.btn {
						width: 0.5rem;
						height: 0.35rem;
						margin: 0 0.1rem;
						&.btn-minus {
							@include bgv("popup-confirm-minus");
						}
						&.btn-plus {
							@include bgv("popup-confirm-plus");
						}
					}
				}
			}
			.group {
				display: flex;
				justify-content: center;
				margin: auto auto 0.18rem;
				.btn {
					margin: 0 0.2rem;
					@include button;
					font-size: 0.24rem;
					text-transform: uppercase;
					font-weight: 600;
					color: #85783f;
				}
			}
		}
	}
}
