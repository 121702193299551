.modal-history {
	.modal__container {
		.modal__content {
			padding: 0 0.3rem 0.43rem;
			.title {
				margin-top: 0.06rem;
			}
			.tab {
				margin-top: 0.1rem;
				height: calc(100% - 0.666rem);
				.tab-header {
					@include flexrc;
					.btn-tab {
						width: calc(1.334rem * 1.15);
						height: calc(0.32rem * 1.15);
						@include bgv("popup-history-tab-inactive");
						text-align: center;
						text-transform: uppercase;
						cursor: pointer;
						position: relative;
						font-size: 0.15rem;
						line-height: calc(0.32rem * 1.15);
						font-weight: 600;
						color: #fff;
						&.active {
							color: #5f5f5f;
							@include bgv("popup-history-tab-active");
						}
					}
				}
				.tab-content {
					width: 90%;
					height: calc(100% - 0.52rem);
					margin: 0.2rem auto 0;
					padding: 0 0.3rem;
					overflow-y: auto;
					@include scrollbar(
						#ffe940,
						rgba(255, 233, 64, 0.25098039215686274),
						0rem
					);
					table,
					td,
					th {
						border: 0.02rem solid #fff;
					}
					table {
						width: 100%;
						margin: 0 auto;
						border-collapse: collapse;
						border-bottom: none;
						border-left: none;
						border-right: none;

						th,
						td {
							font-size: 0.2rem;
							color: #d3d3d3;
							font-weight: 600;
							text-transform: uppercase;
							text-align: left;
							line-height: 0.26rem;
							padding: 0.15rem 0 0.15rem 0.17rem;
							&:nth-child(1) {
								width: 50%;
							}
						}
						th {
							color: #fce84d;
							font-size: 0.24rem;
							line-height: 0.3rem;
							border-left: 0.01rem solid #fff;
							&:nth-child(1) {
								border-left: 0.05rem solid#fce84d;
							}
						}
						td {
							border-left: none;
							border-right: none;
							border-top: none;
							border-bottom: none;
						}
					}
				}
			}
			.wrapper {
				width: 100%;
				height: 100%;
				@include flexcc;
				padding-bottom: 0.1rem;
				justify-content: space-between;

				.btn-close {
					width: 23%;
					height: 15%;
					@include button;
					font-size: 0.2rem;
					text-transform: uppercase;
					font-weight: 600;
				}
			}
		}
	}
}
