.home {
	position: relative;
	width: 100%;
	height: 100%;
	padding-left: 1.25rem;
	.content-wrapper {
		position: relative;
		top: 1rem;
		height: 5.7rem;
		margin: auto;

		.right-side {
			padding-bottom: 0.03rem;
			position: absolute;
			right: 0;
			top: -0.9rem;
			width: 7.96rem;
			height: 6.6rem;
			padding-top: 0.8rem;

			&::before {
				content: " ";
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				@include bgv("right-bg");
				background-position: top left 1.1rem !important;
			}

			&__wrapper {
				position: relative;
				left: 1rem;
				top: 0;
				width: 100%;
				height: 100%;
			}

			&__title {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				@include bgv("title");
				// width: 3.26rem * 1.2;
				// height: 0.83rem * 1.2;
				width: 3.72rem;
				height: 0.94rem;
				z-index: 2;
			}

			&__rewards {
				z-index: 1;
				position: absolute;
				top: 15%;
				left: 50%;
				transform: translateX(-50%);

				@include flexcc;

				.prize {
					// margin-top: 0.2rem;
					// width: 2.695rem;
					// height: 3.28rem;
					width: 3.36rem;
					height: 3.87rem;
					@include bgv("kv");
					position: relative;
					z-index: 3;
					// filter: drop-shadow(0 0 3px yellow);
				}

				&::before {
					content: " ";
					position: absolute;
					left: 50%;
					// top: 50%;
					bottom: 0;
					transform: translate(-50%, 0%) scale(0.9);
					width: 3.36rem;
					// height: 3.87rem;
					height: 3.1rem;
					// border: 1px solid red;
					animation: co 1s linear infinite alternate;

					// @include bgv("box-glow");
				}

				.mark {
					position: absolute;
					top: 24.5%;
					right: 7%;
					width: 0.4rem;
					height: 0.4rem;
					transform: translateY(-50%);
					z-index: 4;
					@include bgc;
					@include bgv("ico-warning");
				}
			}

			&__buttons {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: 0.48rem;
				z-index: 2;
				width: 3.5rem;
				@include flexrc;
				justify-content: space-between;

				.btn-spin {
					height: 0.42rem * 1.1;
					width: 1.39rem * 1.1;
					@include bgv("btn-long");
					color: #000;
					> div {
						padding: 0 0.13rem;
						@include flexrc;
						> span {
							font-size: 0.16rem;
							line-height: 1;
							text-transform: uppercase;
						}
						.tokens {
							@include flexrc;
							margin-right: 0.07rem;
							span {
								font-size: 0.24rem;
								line-height: 0.28rem;
								font-weight: 700;
							}
							.cp-icon {
								margin-left: 0.06rem;
								width: 0.2rem;
								height: 0.2rem;
								@include bgv("token-cp");
							}
						}
					}
				}
			}

			.btn-history {
				@include bgv("btn-spin-history");
				position: absolute;
				right: 0;
				top: 73.4%;
				width: 16.5%;
				height: 10%;
			}
		}
	}
}

@keyframes co {
	from {
		box-shadow: 0px 0px 0px #ffe940;
	}
	to {
		box-shadow: 0rem 0rem 0.35rem #ffe940;
	}
}
