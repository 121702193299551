.modal-confirm-exchange {
	.modal__container {
		@include flexcc;
		.modal__content {
			@include flexcc;
			.title {
				margin-top: 0.2rem;
			}
			.text {
				margin: auto auto 0;
				font-weight: 600;
				text-align: center;
				font-size: 0.22rem;
				line-height: 0.26rem;
				white-space: pre-line;
				color: #fff;
				y {
					color: #ffe940;
					text-transform: capitalize;
				}
				.cp-ico {
					display: inline-block;
					margin: 0 0.5ch;
					width: 0.22rem;
					height: 0.22rem;
					@include bgv("token-cp");
					vertical-align: middle;
				}
				.emblem-ico {
					display: inline-block;
					// margin: 0 0.5ch;
					width: 0.471rem;
					height: 0.3rem;
					@include bgv("token-emblem");
					vertical-align: middle;
				}
			}
			.item {
				margin: auto;
				.item__frame {
					margin: 0 auto;
					width: 1.1rem;
					height: 1.1rem;
					@include flexcc;
					@include rarity-codm;
					justify-content: flex-start;
					.item__img {
						width: 100%;
						height: 85%;
						@include flexcc;
						img {
							height: 100%;
							max-width: 100%;
						}
					}
				}
			}
			.group {
				display: flex;
				justify-content: center;
				margin: auto auto 0.18rem;
				.btn {
					margin: 0 0.2rem;
					@include button;
					font-size: 0.24rem;
					text-transform: uppercase;
					font-weight: 600;
					color: #85783f;
				}
			}
		}
	}
}
