.modal__container.policy {
	.modal__content {
		@include flexcc;
		justify-content: space-between;
		padding-bottom: 0.1rem;
		.policy-content {
			width: 100%;
			height: 83%;
			font-family: inherit !important;
			padding: 0.2rem 0.3rem 0;
			.title-text {
				font-size: 0.2rem;
				line-height: 0.28rem;
				color: #fff;
				padding: 0 0 0.08rem;
				text-align: center;
				p:nth-child(1) {
					font-size: 0.3rem;
					line-height: 0.34rem;
					// margin-bottom: 0.08rem;
				}
			}
			.text__wrapper {
				padding: 0.2rem 0.3rem;
				background: rgba(255, 255, 255, 0.1);
				overflow-y: auto;
				@include scrollbar(
					#ffe940,
					rgba(255, 233, 64, 0.25098039215686274),
					0rem
				);
				height: 65%;

				.text {
					font-size: 0.2rem;
					color: #fff;
					p {
						font-size: 0.2rem;
						line-height: 0.34rem;
					}
				}
			}
		}
		.group {
			@include flexrc;
			width: 100%;
			margin: auto auto 0.18rem;
			// background-color: #f9ebcf;
			.btn {
				width: 1.55rem;
				height: 0.44rem;
				font-size: 0.23rem;
				line-height: 0.44rem;
				@include bgc;
				@include button;
				color: rgba(0, 0, 0, 0.749);
				margin: 0 0.13rem;
			}
		}
	}
}
