.modal-pools {
	.modal__container {
		@include flexcc;
		.modal__content {
			@include flexcc;
			.title {
				margin-top: 0.06rem;
			}
			.items__container {
				width: 80%;
				height: 2.8rem;
				margin: auto auto;
				overflow-y: auto;
				// outline: 1px solid red;
				@include scrollbar(
					#ffe940,
					rgba(255, 233, 64, 0.25098039215686274),
					0rem
				);
				.items__wrapper {
					width: 100%;
					height: 100%;
					margin: 0 auto;
					padding: 0 1.7%;

					.items__content {
						@include flexrc;
						justify-content: flex-start;
						align-items: flex-start;
						flex-wrap: wrap;
						width: 100%;
						height: 100%;
						$count: 5;
						$size: 1.1rem;
						$diff: calc(100% - (#{$size} * #{$count}));
						$halfMargin: calc(#{$diff} / #{$count} / 2);
						.item {
							margin: 0 #{$halfMargin} 0.15rem;
							&:nth-last-child(-n + #{$count}) {
								margin-bottom: 0;
							}
							.item__frame {
								margin: 0 auto;
								width: $size;
								height: $size;
								@include flexcc;
								justify-content: flex-start;
								@include rarity-codm;
								&.non-item {
									.item__img {
										height: 100%;
									}
								}
								.item__img {
									width: 100%;
									height: 80%;
									@include flexcc;
									justify-content: flex-start;
									img {
										height: 100%;
										max-width: 100%;
									}
								}
							}
							.item__name {
								width: $size;
								margin-top: 0.04rem;
								font-size: 0.14rem;
								line-height: 0.18rem;
								text-align: center;
								text-transform: uppercase;
								font-weight: 600;
								color: #fff;
								// @include ellipsis-line(2);
							}
						}
					}
				}
			}
		}
	}
}
