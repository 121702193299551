.itemsbox {
	// width: 6.47rem;
	// height: 4.35rem;
	// width: 5.69rem;
	// height: 4.71rem;
	width: 6.47rem;
	height: 5.36rem;
	align-self: flex-start;
	position: absolute;
	left: 0;
	top: 0rem;
	@include bgv("shop-bg");
	// padding: 2.5% 2.7% 2.5% 2.6%;
	padding: 0.69rem 0.22rem 0.55rem 0.29rem;
	z-index: 3;

	.item-box {
		width: 100%;
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		overflow-y: auto;
		padding-right: 0.29rem;
		&::-webkit-scrollbar {
			width: 0.05rem;
		}
		&::-webkit-scrollbar-thumb {
			background: #f2e02f 0% 0% no-repeat padding-box;
		}
		&::-webkit-scrollbar-track {
			background: rgba(0, 0, 0, 0.9) 0% 0% no-repeat padding-box;
		}

		.item-container {
			position: relative;
			// width: 1.28rem;
			// height: 1.82rem;
			width: 1.28rem;
			height: 1.88rem;
			margin-bottom: 0.35rem;
			margin-right: calc((100% - (1.28rem * 4)) / 3);
			&:nth-child(4n) {
				margin-right: 0;
			}
			&:nth-last-child(-n + 4) {
				margin-bottom: 0;
			}
			&::before {
				content: " ";
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				width: 1.28rem;
				height: 1.88rem;
				@include bgv("item-bg");
				background-size: 1.28rem 1.88rem !important;
			}

			.item {
				position: absolute;
				top: 0.14rem;
				left: 50%;
				transform: translateX(-50%);
				z-index: 1;
				width: 100%;

				.item__frame {
					margin: 0 auto;
					width: 0.95rem;
					height: 0.95rem;
					@include flexcc;
					@include rarity-codm;
					justify-content: flex-start;
					&.non-item {
						@include bgv("reward-box-bg");
						.item__img {
							@include bgv("exchange-emblem");
							background-size: contain !important;
							height: 100%;
						}
					}
					.item__img {
						width: 100%;
						height: 85%;
						@include flexcc;

						img {
							height: 100%;
							max-width: 100%;
						}
					}
				}
				.item__name {
					padding: 0.02rem 0.15rem;
					font-size: 0.13rem;
					line-height: 0.16rem;
					text-align: center;
					text-transform: uppercase;
					font-style: italic;
					font-weight: 700;
					vertical-align: middle;
					display: inline-block;
					width: 100%;
					color: var(--clr-item-name);
					@include ellipsis-line(2);
				}
			}
			.btn-buy {
				position: absolute;
				bottom: 0;
				height: 0.39rem;
				width: 1.28rem;
				@include bgv("btn-long");
				div {
					@include flexrc;
					font-size: 0.2rem;
					line-height: 0.3rem;
					font-weight: 700;
					text-transform: uppercase;
					font-style: italic;
					color: #000;

					.emblem-ico {
						margin: 0 0.01rem;
						// width: 0.34rem * 0.6;
						// height: 0.214rem * 0.6;
						width: 0.471rem * 0.75;
						height: 0.3rem * 0.75;
						@include bgv("token-emblem");
					}
					.shards-ico {
						margin: 0 0.08rem;
						width: 0.33rem * 0.75;
						height: 0.23rem * 0.75;
						@include bgv("token-shard");
					}
				}
			}
		}
	}
}
