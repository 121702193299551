.modal-confirm-spin {
	.modal__container {
		@include flexcc;
		.modal__content {
			// height: calc(100% - 0.6rem) !important;
			@include flexcc;
			.title {
				margin-top: 0.6rem;
			}
			.text {
				margin: auto auto 0;
				font-weight: 600;
				text-align: center;
				font-size: 0.22rem;
				line-height: 0.26rem;
				color: #fff;
				y {
					color: #ffe940;
				}
				.cp-ico {
					display: inline-block;
					margin: 0 0.5ch;
					width: 0.22rem;
					height: 0.22rem;
					@include bgv("token-cp");
					vertical-align: middle;
				}
			}
			.message {
				font-size: 0.22rem;
				line-height: 0.28rem;
				color: #ffe940;
				@include flexrc;
				margin-top: 0.6rem;
				font-style: italic;
				.checkbox {
					display: inline-block;
					margin-right: 1.5ch;
					width: 0.24rem;
					height: 0.24rem;
					background: rgba(255, 255, 255, 0.1);
					// @include bgv("popup-confirm-checkbox");
					border: 0.01rem solid #ffe940;
					&.active {
						@include flexcc;
						&::before {
							content: " ";
							width: 0.22rem;
							height: 0.22rem;
							@include bgv("popup-confirm-tick");
						}
					}
				}
			}
			.group {
				display: flex;
				justify-content: center;
				margin: auto auto 0.18rem;
				.btn {
					margin: 0 0.2rem;
					@include button;
					font-size: 0.24rem;
					text-transform: uppercase;
					font-weight: 600;
					color: #85783f;
				}
			}
		}
	}
}
