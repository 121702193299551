.modal-info {
	.modal__container {
		.modal__content {
			@include flexcc;
			justify-content: flex-start;
			padding: 0 0.3rem 0.33rem;
			.title {
				margin-top: 0.06rem;
			}
			.rules__container {
				max-height: calc(100% - 0.666rem);
				overflow-y: scroll;
				padding: 0 0.3rem;
				margin: 0.3rem auto 0;
				@include scrollbar(
					#ffe940,
					rgba(255, 233, 64, 0.25098039215686274),
					0rem
				);

				.text {
					padding-right: 0.2rem;
					color: #ffffff;
					font-size: 0.19rem;
					line-height: 0.3rem;

					a {
						color: #ffe940;
					}
				}
			}
		}
	}
}
