/* SETUP FOR KEEP ASPECT RATIO VIEWPORT */
body {
	width: 100%;
	height: 100%;
	@include bgv("main-bg");
	background-size: cover !important;
	font-family: $rfg-font-family;
}

#root {
	position: absolute;
	width: 100%;
	height: 100%;
	font-size: 0.36rem;
}

#Main {
	position: fixed;
	margin: 0 auto;
	top: 0 !important;
	right: 0;
	left: 0;
	bottom: 0;
	/* setup layout size */
	width: 13.34rem;
	height: 6.6rem;

	&:before {
		content: "";
		position: fixed;
		z-index: -2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		// background-size: 13.34rem 6.6rem;
	}

	.content-container {
		width: 100%;
		height: 100%;
		position: relative;
	}
}
/* Modal layout setup */
.react-responsive-modal-root {
	display: flex;
	align-items: center;
	width: 100%;
	height: 6.6rem;
}

.react-responsive-modal-overlay {
	overflow: hidden;
}

.react-responsive-modal-container {
	// width: 13.34rem !important;
	// height: 6.6rem !important;
	position: relative;
	display: flex;
	align-items: center;
	margin: 0 auto;
	font-size: 0.36rem;
	overflow: visible;
}

.react-responsive-modal-modal {
	margin: 0 auto !important;
	max-width: none !important;
	padding: 0;
	background: none;
	box-shadow: none;
	overflow: visible !important;
	@include bgv("popup-base-bg");
	width: 8.75rem;
	height: 4.47rem;
	.modal__container {
		height: 100%;
		.modal__title {
			height: 0.4rem;
			&::before {
				content: " ";
				position: absolute;
				left: 0;
				top: 0;
				transform: translate(-14%, -17%);
				width: 0.58rem;
				height: 0.57rem;
				@include bgv("popup-m");
				background-size: 0.58rem 0.57rem;
			}
			&::after {
				content: " ";
				position: absolute;
				left: 0;
				top: 0;
				transform: translate(-14.5%, -16.5%);
				width: 0.58rem;
				height: 0.57rem;
				@include bgv("popup-animated");

				animation: rotate 2.8s linear infinite;
				z-index: 3;
			}
			h3 {
				position: absolute;
				left: 0.54rem;
				top: 0;
				letter-spacing: 0rem;
				color: #fff;
				text-transform: uppercase;
				font-size: 0.25rem;
				line-height: 0.3rem;
				font-weight: 600;
				text-align: center;
			}
		}
		.modal__content {
			height: calc(100% - 0.4rem);
			padding-bottom: 0.11rem;
			width: 100%;
			.title {
				color: #ffe940;
				font-size: 0.54rem;
				line-height: 0.56rem;
				font-weight: 700;
				text-transform: uppercase;
				text-align: center;
			}
		}
	}
}

.modal__overlay,
.toast__overlay {
	@include bgv("popup-bg-overlay");
	// width: 13.36rem;
	// height: 6.6rem;
	// position: absolute;
	width: 100%;
	height: 100%;
	margin: auto;
}

.btn-close {
	cursor: pointer;
	display: block;
	top: 0%;
	right: 0%;
	transform: translate(-11%, 5%);
	outline: none !important;
	border: none !important;
	width: 0.36rem;
	height: 0.36rem;
	&:hover {
		filter: brightness(1.2);
	}
	.ico-close {
		width: 100%;
		height: 100%;
		@include bgv("popup-close");
	}
}

@keyframes rotate {
	from {
		transform: translate(-14.5%, -16.5%) rotate(0);
	}
	to {
		transform: translate(-14.5%, -16.5%) rotate(360deg);
	}
}
