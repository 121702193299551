@import "variable";
@import "font";
@import "reset";
@import "mixins";
@import "functions";
@import "lang";
@import "root";
@import "animate";

/* setup for keep aspect ratio viewport*/
@import "layout";

/* FIXED FLICKER MODAL */
.react-responsive-modal-overlay,
.react-responsive-modal-container,
.react-responsive-modal-modal,
.react-responsive-modal-root {
	animation-fill-mode: forwards !important;
	top: 0 !important;
}

html {
	top: 0;
	position: fixed;
	width: 100%;
	height: 100%;
	font-family: $gnr-font-family;
}

body {
	width: 100%;
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #000;
}

.gunar {
	font-family: $gnr-font-family;
}

.refrigerator {
	font-family: $rfg-font-family;
}

// component
@import "pages/home";
@import "partials/header";
@import "partials/items";
@import "partials/modal/index";
@import "partials/toast";
